import { Component, OnInit } from '@angular/core';
import { Auth } from "@aws-amplify/auth";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'frontend';
  isUserLoggedIn: boolean = false;
  userName: string = '';

  ngOnInit(): void {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.isUserLoggedIn = true;
        this.userName = user?.username;
      })
      .catch(err => console.log(err))
  }

  login() {
    Auth.federatedSignIn();
  }

  logout() {
    this.isUserLoggedIn = false;
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err))
  }
}
