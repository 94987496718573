import { Component, Input, Renderer2 } from '@angular/core';
import { IEmail } from '../models/Email';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { Auth } from "@aws-amplify/auth";
import { EmailDetailsService } from '../email-details.service';

@Component({
  selector: 'app-email-details',
  templateUrl: './email-details.component.html',
  styleUrls: ['./email-details.component.scss']
})
export class EmailDetailsComponent {

  @Input()
  email!: IEmail;
  htmlContent: string|undefined = undefined;
  constructor(private renderer: Renderer2, private emailDetailsService: EmailDetailsService) {
  }

  ngOnInit() {
    this.emailDetailsService.getEmail(this.email.userName, this.email.sortKey).then(email => { if (email) this.email = email });
  }

  async download(attachmentKey: string, filename: string | undefined) {

    const getObjectParams = {
      Bucket: 'andav-email-storage',
      Key: attachmentKey
    };
    const credentials = await Auth.currentUserCredentials();
    const client = new S3Client({ region: 'eu-west-1', credentials: credentials });
    const command = new GetObjectCommand(getObjectParams);
    const url = await getSignedUrl(client, command, { expiresIn: 3600 });

    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    if (filename) {
      link.setAttribute('download', filename);
    }
    link.click();
    link.remove();
  }
  
  private htmlPopup(htmlContent :string){
    const htmlWindow = window.open("");
    if (!htmlWindow) {
      alert("Please allow popups for this website");
      return;
    }
    if(this.htmlContent){
      htmlWindow.document.body.innerHTML = this.htmlContent;
    }
  }

  showHtml() {
    if(this.email.hasHTML){    
      if (this.htmlContent) {
        this.htmlPopup(this.htmlContent)
    }
    else {
      this.emailDetailsService.getEmailHTML(this.email.userName, this.email.objectKey).then(html => {
        this.htmlContent = html;
        this.htmlPopup(this.htmlContent)
      });
    }
}

  }
}
