<mat-accordion class="example-headers-align" multi="false" hideToggle="true">
    <mat-expansion-panel (opened)="onOpenEmail(email.objectKey)" *ngFor="let email of dataSource">
        <mat-expansion-panel-header class="email-row" expandedHeight="48px">
            <div style="width: 100%;">
                <mat-panel-title class="email-row-header">
                    <span class="email-header-text" title="{{email.from?.address}}">{{email.from?.name || email.from?.address}} </span>
                    <span title="{{email.date}}">{{email.date | timeAgo}}</span>
                </mat-panel-title>
                <mat-panel-description class="email-row-description">
                    <span class="email-header-text">{{email.subject}}</span>
                    <mat-icon fontIcon="attachment" *ngIf="email.hasAttachments"></mat-icon>
                </mat-panel-description>
            </div>
        </mat-expansion-panel-header>

        <app-email-details *ngIf="openEmailObjectKey === email.objectKey" [email]="email"></app-email-details>

    </mat-expansion-panel>

</mat-accordion>