import { Injectable } from '@angular/core';
import { Auth } from "@aws-amplify/auth";
import { DynamoDBClient} from "@aws-sdk/client-dynamodb";
import { DynamoDBDocument, QueryCommandInput } from '@aws-sdk/lib-dynamodb';
import { IEmail } from './models/Email';

@Injectable({
  providedIn: 'root'
})
export class EmailListService {

  private documentClient: DynamoDBDocument | undefined;

  private async getDynamoDBDocument(): Promise<DynamoDBDocument | undefined> {
    if (!this.documentClient) {
      const credentials = await Auth.currentUserCredentials();
      const REGION = "eu-west-1";
      const ddbClient = new DynamoDBClient({
        region: REGION,
        credentials: credentials
      });
      this.documentClient = DynamoDBDocument.from(ddbClient);
    }
    return this.documentClient;
  }

  public async getAllEmails(): Promise<IEmail[]> {
    const documentClient = await this.getDynamoDBDocument();
    const params: QueryCommandInput = {
      TableName: "InfrastructureStack-emailsTable5A43863E-1XTQUR4E1U66S",
      ProjectionExpression: "userName, sortKey, objectKey, #f, subject, #d, hasAttachments",
      ScanIndexForward: false,
      //ExclusiveStartKey: {
      //    "userName": "dav",
      //    "sortKey": "1685818826130|d4oc8nkphu7juuh5otjuomp377c6oovt6nom2no1"
      //},
      ExpressionAttributeValues: {
        ":userName": 'dav'
      },
      KeyConditionExpression: "userName = :userName",
      ExpressionAttributeNames: {
        "#f": "from",
        "#d": "date"
      }
    };
    const results = await documentClient?.query(params);
    return results?.Items as IEmail[];
  }
}
