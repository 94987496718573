import { Injectable } from '@angular/core';
import { IDBPDatabase, openDB } from 'idb';
import { Auth } from "@aws-amplify/auth";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocument, GetCommandInput } from '@aws-sdk/lib-dynamodb';
import { IEmail } from './models/Email';
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";

const TABLE_NAME = 'emails';
const DATABASE_NAME = 'emailDB';

@Injectable({
  providedIn: 'root'
})

export class EmailDetailsService {
  private db: IDBPDatabase<any> | undefined;
  private documentClient: DynamoDBDocument | undefined;

  constructor() { }

  private async getObjectStore(): Promise<IDBPDatabase<any> | undefined> {
    if (!this.db) {
      try {
        this.db = await openDB(DATABASE_NAME, 1, {
          upgrade(db: IDBPDatabase) {

            if (db.objectStoreNames.contains(TABLE_NAME)) {
              return;
            }
            db.createObjectStore(TABLE_NAME);
          },
        });
      } catch (error) {
        console.log('Error opening database', error);
      }
    }
    return this.db;
  }


  private async getDynamoDBDocument(): Promise<DynamoDBDocument | undefined> {
    if (!this.documentClient) {
      const credentials = await Auth.currentUserCredentials();
      const REGION = "eu-west-1";
      const ddbClient = new DynamoDBClient({
        region: REGION,
        credentials: credentials
      });
      this.documentClient = DynamoDBDocument.from(ddbClient);
    }
    return this.documentClient;
  }

  public async getEmail(userName: string, sortKey: string): Promise<IEmail | undefined> {
    let email: IEmail | undefined = await this.getValueFromIndexedDB(sortKey) as IEmail;
    if (email) {
      return email;
    }
    email = await this.getValueFromDynamoDB(userName, sortKey) as IEmail;
    if (email) {
      await this.putValueToIndexedDB(sortKey, email);
    }
    return email;
  }

  public async getEmailHTML(userName: string, objectKey: string): Promise<string> {
    const htmlKey = objectKey + '.html';
    let html: string = await this.getValueFromIndexedDB(htmlKey);
    if (html) {
      return html;
    }
    html = await this.getContentFromS3(htmlKey)
    if (html && html.length > 0) {
      await this.putValueToIndexedDB(htmlKey, html);
    }
    return html;
  }

  private async getValueFromDynamoDB(userName: string, sortKey: string): Promise<any> {
    const documentClient = await this.getDynamoDBDocument();
    const params: GetCommandInput = {
      TableName: "InfrastructureStack-emailsTable5A43863E-1XTQUR4E1U66S",
      Key: {
        "userName": userName,
        "sortKey": sortKey
      }
    };
    const results = await documentClient?.get(params);
    return results?.Item;
  }

  
  private async getContentFromS3(objectKey :string) {
    const command = new GetObjectCommand({
      Bucket: 'andav-email-storage',
      Key: objectKey
    });
  
    const credentials = await Auth.currentUserCredentials();
    const client = new S3Client({ region: 'eu-west-1', credentials: credentials });
    const response = await client.send(command);
    if(response && response.Body) {
      return await response.Body.transformToString();
    }
    return '';
  }

  private async getValueFromIndexedDB(objectKey: string): Promise<any> {
    const db = await this.getObjectStore();
    if (db) {
      const tx = db.transaction(TABLE_NAME, 'readonly');
      const store = tx.objectStore(TABLE_NAME);
      const result = await store.get(objectKey);
      return result;
    }
    return;
  }

  private async putValueToIndexedDB(objectKey: string, value: any) {
    const db = await this.getObjectStore();
    if (db) {
      const tx = db.transaction(TABLE_NAME, 'readwrite');
      const store = tx.objectStore(TABLE_NAME);
      const result = await store.put(value, objectKey);
    }
  }
}
