<div class="app-root">


  <span *ngIf="isUserLoggedIn">
    <mat-toolbar>
      <span>Inbox</span>
      <span class="spacer"></span>
      <span>{{userName}}</span>
      <button (click)="logout()" mat-icon-button aria-label="Logout">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </mat-toolbar>
    <app-email-list></app-email-list>
  </span>
  <span *ngIf="!isUserLoggedIn">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Disposable Email</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>Please login!</p>
      </mat-card-content>
      <mat-card-actions align="end">
        <button (click)="login()" mat-button aria-label="Login">
          <span>Login</span>
          <mat-icon>open_in_browser</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </span>
</div>