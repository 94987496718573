import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  constructor() {
    dayjs.extend(relativeTime)
  }

  transform(value: string|undefined): string {
    return dayjs(value).fromNow(true);
  }

}
