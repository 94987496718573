export const awsconfig =
{
    Auth: {
        region: 'eu-west-1',
        identityPoolId: 'eu-west-1:34758784-fd21-4ac2-9b5b-9c4ab2933de7',
        userPoolId: 'eu-west-1_PMPmX3CQH',
        userPoolWebClientId: 'qrc5jscubcrq68mrf29b88ti0',
        mandatorySignIn: true,
        oauth: {
            domain: 'auth.andav.org',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'https://mail.andav.org/',
            redirectSignOut: 'https://mail.andav.org/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
}