<div class="email-header-container">
    <div title="{{email.from?.address}}">
        <span class="email-header-title">From: </span>
        <span>{{email.from?.name}}</span>
    </div>
    <div *ngFor="let to of email.to">
        <span class="email-header-title">To: </span>
        <span title="{{to.address}}">{{to.name || to.address}}</span>
    </div>
    <div *ngFor="let cc of email.cc">
        <span class="email-header-title">CC: </span>
        <span title="{{cc.address}}">{{cc.name || cc.address}}</span>
    </div>
    <div>
        <span class="email-header-title">Subject: </span>
        <span>{{email.subject}}</span>
    </div>
</div>
<mat-divider></mat-divider>
<div style="display: flex;">
    <div *ngFor="let attachment of email.attachments">
        <div style="display: flex; border: 1px solid #0000001f; padding-right: 1em;">
            <div>
                <mat-icon fontIcon="attachment"></mat-icon>
            </div>
            <div>
                <div>
                    <a href="#" (click)="download(attachment.key, attachment.filename)">{{attachment.filename || "unnamed"}}</a>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div style="padding-right: 6px;">{{attachment.contentType}}</div>
                    <div>{{attachment.size | fileSize}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-divider *ngIf="email.attachments && email.attachments.length > 0"></mat-divider>
<div class="email-text">{{email.text}} </div>

<mat-action-row>
    <button mat-button color="primary" *ngIf="email.hasHTML" (click)="showHtml()">{{'HTML'}}</button>
</mat-action-row>