import { Component } from '@angular/core';
import { EmailListService } from '../email-list.service';
import { IEmail } from '../models/Email';

@Component({
  selector: 'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})
export class EmailListComponent {
  dataSource: IEmail[] = [];
  openEmailObjectKey: string = "";

  constructor(private emailListService: EmailListService) {

  }

  ngOnInit(): void {
    this.emailListService.getAllEmails().then((response) => {
      this.dataSource = response.sort((a, b) => b.date.localeCompare(a.date));
    });
  }

  onOpenEmail(objectKey: string) {
    this.openEmailObjectKey = objectKey;
  }
}
